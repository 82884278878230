@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Montserrat+Alternates:wght@100&family=Montserrat:wght@300;400;700&display=swap');


* {
    padding: 0;
    margin: 0;
}

.Main_div {

    background-image: url("../../assets/hero.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 111vh;

}

.center-content {
    margin-top: 100px;
    position: relative;
}

.center-content h1 {
    color: #FFF;
    text-align: center;
    text-shadow: 20px 14px 10px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 4.8px;

}

.center-content h2 {
    color: #ADFFE9;
    text-align: center;
    text-shadow: 20px 14px 10px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 46px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.learnmore {
    position: absolute;
    width: 220px;
    height: 59px;
    border-radius: 17px;
    background: #FFF;
    color: #000;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;

    left: 640px;
    top: 145px;
    cursor: pointer;

}

.learnmore:hover {
    background-color: #4BE4B4;
}

@media (max-width: 768px) {
    .Main_div {
        background-image: url("../../assets/phoneview_korslet.png");
        /* height: 55vh; */
    }

    .center-content {
        margin-top: 10px;
        position: relative;
    }

    .center-content h1 {
        margin-top: 120px;
        font-size: 50px;
        font-family: Montserrat;
        font-weight: 500;

        letter-spacing: 2.8px;
        margin-left: 20px;

    }

    .center-content h2 {


        font-size: 30px;
        margin-top: 15px;

        line-height: 30px;
        margin-left: 17px;
    }

    .learnmore {
        position: absolute;
        width: 160px;
        height: 50px;
        border-radius: 13px;
        background: #FFF;
        color: #000;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;

        left: 101px;
        top: 140px;
    }

}

/* @media (min-width: 768px) and (max-width: 991px)   */
@media screen and (max-width: 480px)
{

    .center-content h1 {
        font-size: 40px;
        font-family: Montserrat;
        font-weight: 500;

        letter-spacing: 1.5px;
        margin-bottom: 20px;

    }

   

    .center-content h2 {


        font-size: 35px;


        line-height: 30px;
    }

    .learnmore {

        width: 140px;
        height: 45px;
        font-size: 18px;

        left: 115px;
        top: 140px;
        cursor: pointer;

    }

    .Main_div {
        height: 79vh;
    }

    .Main_div {
        background-image: url("../../assets/phoneview_korslet.png");
        height: 100vh;
    }

}

/* @media (width: 1280px) {
    .learnmore {

        left: 520px;

    }
  
} */

@media (min-width: 412px) and (max-width: 912px){
    .learnmore {
        position: absolute;
        width: 180px;
        height: 49px;
        border-radius: 17px;
        background: #FFF;
        color: #000;
        font-family: Open Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
    
        left: 110px;
        top: 130px;
        cursor: pointer;
    
    }
   
    
}
@media (min-width: 769px)  {
  
    /* .center-content h1 {
       
        font-size: 70px;
        font-family: Montserrat;
        font-weight: 900;

        letter-spacing: 10px;
       
        margin-bottom: 40px;

    } */
    /* .center-content h2 {
        letter-spacing: 3px;

        font-size: 50px;
font-weight: 900;

        line-height: 60px;
    } */
    /* .learnmore {

        width: 260px;
        height: 79px;
        font-size: 33px;

        left: 400px;
        top: 170px;
        cursor: pointer;

    } */
    /* .center-content {
        margin-top: 200px;
        position: relative;
    }
    .about_image img{
        display: none;
    } */
}
