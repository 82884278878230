.skillsmain{
    display: flex;
    justify-content: center;
}

.skillheading{
    margin-top: 100px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.skillheading p{
    margin-top: 20px;
    color: rgba(9, 30, 63, 0.80);
text-align: center;
font-family: Open Sans;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.skillheading h1{
    text-align: center;
font-family: Montserrat;
font-size: 55px;
font-style: normal;
font-weight: 600;
line-height: normal;
background: linear-gradient(180deg, #054192 0%, #00B887 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.skillscontainer{
    width: 1093px;
height: 641px;
border-radius: 59px;
border: 4px solid #1EB;
display: flex;
flex-direction: column;
justify-content: center;

}
.wrapper{
    display: flex;
  
    max-height: 120px;
    overflow-x: auto;
   gap: 60px;
   margin-top: 20px;
   overflow-y: hidden;
  
}
.field{
    width: 184px;
height: 53px;

    display: flex;
    align-items: center;
}

.field h1{
    color: #000;
text-align: center;
font-family: Raleway;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.3px;
}


.wrapper::-webkit-scrollbar{
    width: 0;
}

.wrapper .item{
   
    
    line-height: 110px;
   
}

.field h1{
    color: #000;
text-align: center;
font-family: Raleway;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.3px;
}
.webskill1{
    width: 238px;
height: 92px;

}

.webskill{
    width: 84px;
height: 105px;


}
.uiuxskill{
    width: 74.059px;
height: 77.476px;

}


@media (max-width: 768px) {
    
    .wrapper{
       
    
        gap: 33px;
    
        overflow-x: auto;
        margin-top: 40px;
        max-height: 83px;
        
      
    }
 
    .webskill1{
        width: 150px;
        height: 55px;
        margin-left: -10px;
    
    }
    .webskill{
        width: 64px;
    height: 70px;
    
    
    }
    .field h1{
   

    font-size: 18px;
 
    font-weight: 900;
   
    }

    .skillscontainer{
        width: 332px;
    height: 646px;
    border-radius: 59px;
    border: 2px solid #1EB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 17px;
    margin-left: 3px;
    
    }
    .uiuxskill{
        width: 55px;
    height: 57px;

    }
    .skillheading h1{

        font-size: 35px;
        
        }
}
@media (min-width: 768px) and (max-width: 991px){
.skillscontainer {
    width: 768px;
    height: 641px;
    border-radius: 59px;
    border: 4px solid #1EB;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper {
    display: flex;
    max-height: 120px;
    overflow-x: auto;
    gap: 42px;
    margin-top: 20px;
}


}

@media screen and (min-width: 1024px) {
    .skillheading h1{
        text-align: center;
    font-family: Montserrat;
    font-size: 55px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(180deg, #054192 0%, #00B887 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }

    .skillheading p{
     
    font-size: 35px;
 
    }
    
}