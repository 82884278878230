.about_image{
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_image img{
    width: 350px;
    height: 380.481px;

 
}

.about_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.AboutMain{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* justify-content: center;
    align-items: center; */
    
}
.about_blocks1 ,.about_blocks2{
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
}

.about_heading h1{
    font-family: Montserrat;
font-size: 50px;
font-style: normal;
font-weight: 600;
line-height: normal;
background: linear-gradient(180deg, #054192 0%, #249D77 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-bottom: 10px;
text-align: center;
}

.image1{
    display: flex;
    align-items: center;
}

.about_heading p{
    color: rgba(9, 30, 63, 0.80);
font-family: Open Sans;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 26px;
text-align: center;
}

.blocks1_block{
    display: flex;
    width: 356px;
height: 164px;
border-radius: 10px;
background: #FFF;
box-shadow: 0px 0px 25px 0px rgba(5, 65, 146, 0.15);
} 

.image1 img{
    width: 70.602px;
height: 70px;
margin-right: 10px;
}



.reason1{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.reason1 h1{
    color: rgba(9, 30, 63, 0.80);
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.reason1 p{
    color: rgba(54, 51, 51, 0.84);
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 1.5;
/* text-align: justify; */
margin-top: 5px;
padding-right: 15px;

}

@media (max-width: 768px) {
.about_image{
    width: 300px;
 
}

     .about_image img {
        /* width: 270px;
        height: 345px; */
        display: none;
       
     }
/* 
     .about_blocks1 ,.about_blocks2{
        margin-left: 20px;
        gap: 25px;
       
    } */

    /* .about_heading{
        margin-left: 10px;
    } */

    .about_heading h1{
   
    font-size: 26px;
   margin-top: 20px;
    

    }

    .about_heading p{
        color: rgba(9, 30, 63, 0.80);
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
   
    }


     
}
@media (min-width: 768px) and (max-width: 991px){
.about_image img {
    width: 442px;
    height: 484.481px;
    margin-bottom: 20px;
}
.about_content{
    margin-bottom: 25%;
}
}

@media (min-width: 1200px){
.AboutMain{
    margin-bottom: 12%;
}

}
