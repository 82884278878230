.services {
    text-align: center;
    font-family: Montserrat;
    font-size: 55px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(180deg, #054192 0%, #00B887 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.service_tagline {
    color: rgba(9, 30, 63, 0.80);
    text-align: center;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cardmain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.number1{
position: absolute;
top: -120px;
z-index: 1;
background-repeat: no-repeat;

}

.number1 img{
    width: 250.519px;
    height: 235.428px;

}

.intro p{
    margin-left: 0px;
    margin-right: 0px;
    width: 270px;
    padding-left: 20px;
}

/* .nums {
    display: flex;
    justify-content: center;
}

.nums ul {
    display: flex;
    list-style: none;
}

.nums img {
    width: 250.519px;
    height: 235.428px;
    transform: rotate(0.446deg);

} */

.container {
    margin-top: 100px;
    margin-bottom: 20px;
}

.card {
    width: 288.467px;
    height: 405.656px;

    flex-shrink: 0;
    border-radius: 30px;
    background: #f6f6f6;
    overflow: hidden;

    background-image: url("../../assets/appdev.png");
    background-size: cover;

    background-repeat: no-repeat;
    background-position: center bottom;
    position: relative;
}
.card1{
    background-image: url("../../assets/webdev.png");
}
.card2{
    background-image: url("../../assets/digimarketing.png");
}


.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    align-items: center;
    gap: 100px;

}

.card h1 {
    width: 220px;
    height: 61px;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: linear-gradient(180deg, #054192 0%, #1EB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;


}
.intro {
    /* background-image: url("../../assets/upview.png"); */
    height: 70px;
    width: 289px;
    padding: 6px;
  
    position: absolute;
    left: -7px;
    bottom: 0;
    overflow: hidden; 

}




/* 
.intro {
    height: 70px;
    width: 350px;
    padding: 6px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    background-color: black;
    clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 100%);



}
.intro::before{
    border-top-left-radius: 50% 100%;
    border-top-right-radius:50% 100% ;
    position: absolute;
}

.intro::after{
    border-top-left-radius: 50% 100%;
    border-top-right-radius:50% 100% ;
    position: absolute;
} */

.card p {
    color: rgba(76, 75, 75, 0.84);
    /* font-family: Montserrat; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    visibility: hidden;
}


.card:hover {
    cursor: pointer;
}

.card:hover .intro {
     background-image: url("../../assets/upview.png");
     background-repeat: no-repeat;
     background-size: cover;
     width: 300px;
    height: 220px;
    bottom: 2px;
    position: absolute;
    
    /* background-color: white; */
    
}

.card:hover p {
    visibility: visible;
}

.card:hover .imagedigi img {
    transform: scale(1.1) rotate(-3deg);
}




@media (max-width: 768px)  {
    .services {
      margin-top: 100px;
        font-size: 30px;
     
    }

    .service_tagline {
    
        font-size: 20px;
        margin-top: 22px;
        

    }
}
.service_tagline {
    color: rgba(9, 30, 63, 0.80);
    text-align: center;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* @media screen and (max-width: 1024px) {
    .service_tagline {
       
        font-size: 35px;
      
    }
    .card {
        width: 334.467px;
        height: 436.656px;
    }
    .card:hover .intro {
        background-image: url("../../assets/upview.png");
       
        width: 337px;
      
     
       
   }
   .card p {
    color: black;
    font-family: Montserrat;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
   
    width: 300px;
}

} */