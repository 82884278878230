.footercontainer{
  
   position: relative;
    background-size: cover;




   
}
   /* height: 235vh; */
.inputtext{
    padding-left: 20px;
}

.try{
    width: 100%;
 position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/footerbg.png");
    position: absolute;
    top: 500px;
   width: 100%;
}


.contactmaindiv{
    display: flex;
    margin: 0 50px 20px 50px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 45px;
 
   /* position: absolute;
   left: 0;
   right: 0;
   top: 500px; */

 
}

.detail h1 {
    color: #FFF;
font-family: Inter;
font-size: 45px;
font-style: normal;
font-weight: 800;
padding-left: 20px;
line-height: 62.4px; /* 138.667% */
}


.contact_img img{
    width: 651px;
    height: 446px;
    padding-left: 20px;
    flex-shrink: 0;
}

.contact_content{
    margin-left: 30px;
    margin-top: 350px;
}

.detail p{
   
    width: 651px;
    color: #ffffff;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 800;
line-height: 30.6px;
padding-left: 20px;
}

.contact_form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    

    margin-top: 350px;
  
}

.form_container{
    width: 455px;
height: 560px;
border-radius: 25px;
background: #FFF;
box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.18);
}

.heading_main{
    display: flex;
width: 455px;
padding: 41px 0px 38px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 6.813px;
border-radius: 25px 25px 0px 0px;
background: #4BE4B4;
height: 60px;
}

.heading_main h3{
    color: #242C38;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 13.2px; /* 120% */
    text-transform: uppercase;
}

.heading_main h2{
    color: #000;
text-align: center;
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 800;
line-height: 29px; /* 103.571% */
}

.name , .email , .companydetails , .form-group , .submit-button{
    display: flex;
    justify-content: space-around;
    margin-top: 23px;
    position: relative;
}

.submit-button button{
    display: flex;
width: 298.563px;
height: 48px;
padding: 16px 0px 17px 0px;
justify-content: center;
align-items: center;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 14px; 
border-radius: 8px;
background: #080B0E;
border: none;
}

.useremail input{

    width: 435px;
height: 38px;
border-radius: 6px;
border: 1px solid #A7AAAD;
background: #FFF;
}

/* .username , .Lastname{
   
    border-radius: 6px;
border: 1px solid #A7AAAD;
background: #FFF;
} */

.username input , .Lastname input , .companyname input , .title input {
    border: none;
    width: 190px;
    height: 38px;
    border-radius: 6px;
    border: 1px solid #A7AAAD;
    background: #FFF;
}

.form-group input{
    width: 417px;
    height: 68px;
    border-radius: 6px;
    border: 1px solid #A7AAAD;
    background: #FFF;
}

.form-group::before{
    content: 'Message';
    position: absolute;
    top: -10px;
    left: 30px;
    background-color: #fff;
    padding: 0 5px;
    font-size: 14px;
    color:#000000;
}

.username::before{
    content: 'Name';
    position: absolute;
    top:-10px;
    left: 30px;
    background-color: #fff;
   
    font-size: 14px;
    color:#000000;
}

.companyname::before{
    content: 'Company Name';
    position: absolute;
    top:-10px;
    left: 30px;
    background-color: #fff;
   
    font-size: 14px;
    color:#000000;
}

.Lastname::before{
    content: 'Last Name';
    position: absolute;
    top: -10px;
    left: 255px;
    background-color: #fff;
    padding: 0 5px;
    font-size: 14px;
    color:#000000;
}

.title::before{
    content: 'Number';
    position: absolute;
    top: -10px;
    left: 255px;
    background-color: #fff;
    padding: 0 5px;
    font-size: 14px;
    color:#000000;
}

.useremail::before{
    content: 'Email';
    position: absolute;
    top: -10px;
    left: 30px;
    background-color: #fff;
    padding: 0 5px;
    font-size: 14px;
    color:#000000;
}


.FooterMain{
    display: flex;
    flex-wrap: wrap;
    margin-left: 60px;
    margin-right: 60px;
    justify-content: space-between;
    margin-top: 100px;

}

.korsletlogo{
    width: 180px;
height: 180px;
flex-shrink: 0;
}

.ContentFooter{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ContentFooter{
  
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    gap: 50px;
}


.add {
    margin-top: 20px;
    list-style: none;
    color: rgba(255, 255, 255, 0.80);
font-family: Open Sans;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 40px; /* 112% */
}

.Support ul li {
    margin-bottom: 5px;
    list-style: none;
    text-decoration: none;
    

}
.Support .korslet {
    text-decoration: none;
    cursor: pointer;
}

.ContentFooter {
    padding-right: 30px;
    display: flex;
  /* align-items: center;
    justify-content: center; */
}

.contactheading h2 ,.Support h2{
    color: #FFF;
font-family: Open Sans;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.Support h2{
    margin-bottom: 20px;
}

.links .korslet {

    list-style: none;
    color: rgba(255, 255, 255, 0.80);
font-family: Open Sans;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 112% */
}

.socialimg{
    margin-top: 20px;
    display: flex;
    gap: 20px;
    list-style: none;
    text-decoration: none;
}
.socialimg li img{
    width: 31.537px;
height: 30.561px;
flex-shrink: 0;
}

.line{
   
    width: 1215px;
margin: 40px 50px 20px 150px;
    height: 1.5px;
    opacity: 0.5;
background: #FFF;
}
.rights{
    display: flex;
    justify-content: center;
    align-items: center;
}
.rights img{
    margin-top: 30px;
    width: 267.906px;
    height: 25.726px;
    /* margin-left: 150px; */
}

.FooterLogo{
    margin-left: 30px;
}

.small_line{
    width: 100px;
    margin: 10px 50px 10px 50px;
        height: 2px;
        opacity: 0.5;
    background: #FFF;
}

@media (max-width: 767px)  {
    
    .form_container {
        width: 359px;
        height: 658px;
        border-radius: 25px;
        background: #FFF;
        box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.18);
        margin-left: 146px;
    }

    .heading_main {
        display: flex;
        width: 344px;
        height:30px;
        padding: 41px 0px 38px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6.813px;
        border-radius: 25px 25px 0px 0px;
        background: #4BE4B4;
    }
    .username input, .Lastname input, .companyname input, .title input {
        border: none;
        width: 136px;
        height: 40px;
        border-radius: 6px;
        border: 1px solid #A7AAAD;
        background: #FFF;
    }
    .useremail input {
        width: 332px;
        height: 40px;
        border-radius: 6px;
        border: 1px solid #A7AAAD;
        background: #FFF;
    }
    element.style {
        width: 313px;
        height: 70px;
        border: 1px solid rgb(167, 170, 173);
        border-radius: 6px;
        padding: 10px;
        resize: none;
    }

    .contact_content {
        margin-left: -41px;
        margin-top: 560px;
    }
    .contact_img img {
        width: 335px;
        height: 287px;
        padding-left: 10px;
      
    }

    .line{
        width: 300px;
        margin-left: 34px;
    }

    .rights img {
        
        margin-left: 41px;
        margin-bottom: 20px;
    }

    .detail p{
        display: none;
    }

    .contact_form{
        margin-top: 50px;
        width: 55%;
    }

    .detail{
        text-align: center;
    }

    .Support{
        width: 240px;
    }

    .FooterMain{
        flex-direction: column-reverse;
    }

    .FooterLogo{
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 30px;
    }

    .try {
        width: 100%;
        position: absolute;
        background-repeat: no-repeat;

        position: absolute;
        top: -307px;
        width: 100%;
       
    }

    .form-group input{
        width: 306px;
        height: 68px;
      
    }
    .form_container {
        width: 344px;
        height: 495px;

        margin-left: 130px;}

        .blocks1_block {
            display: flex;
            width: 323px;
            height: 164px;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 0px 25px 0px rgba(5, 65, 146, 0.15);
            }
        
            .Lastname::before{
               
                left: 197px;
               
            }
            .name , .email , .companydetails , .form-group , .submit-button{
                display: flex;
                justify-content: space-around;
                margin-top: 20px;
                position: relative;
            }
            .title::before{
                content: 'Number';
                position: absolute;
                top: -10px;
                left: 197px;
                background-color: #fff;
                padding: 0 5px;
                font-size: 14px;
                color:#000000;
            }

            .socialimg{
                margin-left: 25px;
            }
            

}

/* @media (max-width: 1080px) {

.blocks1_block {
    display: flex;
    width: 359px;
    height: 164px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 25px 0px rgba(5, 65, 146, 0.15);
}
  } */

@media (min-width: 992px) and (max-width: 1199px){
    .form-group input{
        width: 310px;
        height: 68px;
      
    }
}
@media (min-width: 768px) and (max-width: 991px){
    .line {
        width: 648px;
        margin: 40px 54px 20px 91px;
        height: 1.5px;
        opacity: 0.5;
        background: #FFF;
    }
    .contact_content {
        margin-left: 30px;
        margin-top: 496px;
    }
    .contact_form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin-top: 110px;
        margin-left: 187px;
    }
    .try {
      
        top: -150px;
        width: 100%;
    }
    .FooterMain{
        flex-wrap: nowrap;
        margin-left: 50px;
        margin-right: 0;
    }
    .contactdetails{
        width: 422px;
    }
   

    .rights img {
        width: 291.906px;
        height: 31.726px;
        margin-left: 45px;
        /* margin-left: 269px; */
    }
    .detail p {
        width: 651px;
        color: #ffffff;
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 35.6px;
        padding-left: 20px;
    }
    .detail h1{
        text-align: center;
        margin-bottom: 20px;
    }
    .useremail input {
        width: 445px;
        height: 33px;
        border-radius: 6px;
        border: 1px solid #A7AAAD;
        background: #FFF;
    }
    .Support{
        margin-right: 261px;
    } 
   
}

@media (min-width: 1200px){
    .try {
      
        top: -80px;
       
        
    }
    .contactmaindiv{
        display: flex;
        margin: 0 50px 20px 50px;
        flex-wrap: nowrap;
        
    
     
    }
    .line {
        display: none;
    }
    .detail p {
        width: 651px;
        color: #ffffff;
        font-family: Inter;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 36.6px;
        padding-left: 20px;
    }
    .contact_content {
        margin-left: -5px;
        margin-top: 350px;
    }
}
@media (width: 412px){
   
    .line{
        width: 350px;
        margin-left: 36px;
    }
    .FooterLogo{
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 60px;
    }
    
}