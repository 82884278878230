.navlinks {
    padding: 10px 80px 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;


}
.add1{
    text-decoration: none;
}
.navbuttonlinks {
    display: flex;


}

.nav-support {
    width: 133px;
    height: 41px;
    border-radius: 25px;
    background: linear-gradient(180deg, #00F5B4 0%, #5CFFD3 100%);
    color: #091E3F;
    font-family: Open Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
}


.korslet_logo {
    width: 114px;
    height: 115px;
    flex-shrink: 0;
}

.nav-links {
    display: flex;
    justify-content: left;
    list-style: none;
}

.nav-links li {


    list-style: none;
    color: #FFF;
    font-family: Open Sans;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0 10px;
    margin: 0 20px;
    cursor: pointer;
    text-decoration: none;
    padding-top: 7px;

}


.mobile-menu-icon {
    border: none;
    background: none;
    font-size: 30px;
    display: none;
  
}


@media (max-width:768px) {

    .navlinks {
        padding: 10px 60px 0 35px;
        
    
    
    }


    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style: none;
        transition: all 0.9s ease-in-out;
        width: 100%;
        min-height: 20vh;
        left: 0;
        top: 10%;
        justify-content: space-between;

        text-align: center;
        align-items: center;
        padding-top: 20px;
        background-color: rgb(0, 0, 0);
        z-index: 1;
    }

    .mobile-menu-icon {
        display: block;
        position: absolute;
        right: 25px;
    }

    .korslet_logo {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
    }

    .nav-links-mobile li {
        padding: 32px 0 32px;
        width: 100%;

        list-style: none;
        color: #FFF;
        font-family: Open Sans;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        cursor: pointer;
        text-decoration: none;

    }

    .nav-support {
        width: 110px;
        height: 40px;
        border-radius: 25px;
        background: linear-gradient(180deg, #00F5B4 0%, #5CFFD3 100%);
        color: #091E3F;
        font-family: Open Sans;
        font-size: 20px;

        font-weight: 600;
        margin-right: 20px;

    }

    .nav-links {
        display: none;
      
    }
}
@media (min-width: 768px) and (max-width: 991px){
    .nav-links {
        display: none;
      
    }

    .nav-links-mobile li {
        padding: 32px 0 32px;
        width: 100%;

        list-style: none;
        color: #FFF;
        font-family: Open Sans;
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        cursor: pointer;
        text-decoration: none;

    }
    .mobile-menu-icon {
        display: block;
        position: absolute;
        right: 25px;
    }

    .navlinks {
        padding: 10px 60px 0 35px;
        
    
    
    }


    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style: none;
        transition: all 0.9s ease-in-out;
        width: 100%;
        min-height: 20vh;
        left: 0;
        top: 10%;
        justify-content: space-between;

        text-align: center;
        align-items: center;
        padding-top: 20px;
        background-color: rgb(0, 0, 0);
        z-index: 1;
    }
}

/* @media  (min-width: 1024px){
    .korslet_logo {
        width: 180px;
        height: 180px;
        flex-shrink: 0;
    }
    .nav-support {
        width: 170px;
        height: 60px;
        border-radius: 25px;
        background: linear-gradient(180deg, #00F5B4 0%, #5CFFD3 100%);
        color: #091E3F;
        font-family: Open Sans;
        font-size: 30px;

        font-weight: 600;
        margin-right: 70px;
        margin-bottom: 20px;

    }
    .mobile-menu-icon {
        display: block;
        position: absolute;
        right: 40px;
        margin-bottom: 20px;
        font-size: 30px;
    }
    .nav-links-mobile li {
      

     
        font-size: 35px;
      

    }
    
} */